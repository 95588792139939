


















































































































































































































































































































































































































































































import Vue from 'vue';
import { CurrencyType, VetAnimalType } from '@/models/user.model';
import { VetSkillType } from '@/models/position.model';
import { User } from '@/models/user.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import constants from '@/constants/constants';
import { db, storage } from '@/main';
import { Place } from '@/models/place.model';
import {
  ContractType,
  PerkType,
  WorkConditionsType
} from '@/models/position.model';

export default Vue.extend({
  name: 'UserProfile',
  components: {
    PlaceSearch: () => import('@/components/PlaceSearch.vue')
  },
  data() {
    return {
      options: {
        apiKey: process.env.VUE_APP_PLACES_API_KEY
      },
      isBusy: false,
      user: {} as User,
      rules: {
        required: (value: string) => !!value || 'Required.',
        min: (value: string) => value.length >= 6 || 'Min 6 characters',
        email: (value: string) =>
          /.+@.+\..+/.test(value) || 'E-mail must be valid',
        mobile: (value: string) =>
          /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(
            value
          ) || 'Mobile number must be valid',
        year: (value: string) =>
          value?.length === 4 || 'Please enter a four digit year',
        animalPreferences: (value: string[]) =>
          value?.length > 0 || 'Please select at least one animal type',
        preferredContractTypes: (value: string[]) =>
          value?.length > 0 ||
          'Please select at least one preferred contract type',
        preferredPerkTypes: (value: string[]) =>
          value?.length > 0 || 'Please select at least one preferred perk',
        descriptionLength: (value: string) =>
          value?.length <= 500 || 'Max 500 characters'
      },
      isUserDetailsFormValid: false,
      isBrandFormValid: false,
      isSkillsFormValid: false,
      isPreferencesFormValid: false,
      isAcademicDetailsFormValid: false,
      isSalaryFormValid: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      vetAnimalTypes: Object.values(VetAnimalType),
      contractTypes: Object.values(ContractType),
      perkTypes: Object.values(PerkType),
      workConditionTypes: Object.values(WorkConditionsType),
      vetSkillLevelTypes: Object.values(VetSkillType),
      currencyTypes: Object.values(CurrencyType),
      academicInstitutions: constants.ACADEMIC_INSTITUTIONS.names,
      accreditationInstitutions: constants.ACCREDITATION_INSTITUTIONS.names,
      vetSkillType: VetSkillType,
      currency: this.$store.state.currentUser.currency,
      uploadProgress: 0,
      profileImage: undefined,
      profileImageUrl: undefined,
      cvFile: undefined,
      cvUrl: undefined,
      place: {} as Place,
      placeLoading: false,
      selectedVetSkills: [] as VetSkillType[],
      originalUser: {} as User
    };
  },
  methods: {
    getData() {
      db.collection('users')
        .doc(this.$store.state.currentUser.uid)
        .get()
        .then(async (doc: firebase.firestore.DocumentData) => {
          this.user = doc.data() as User;
          this.currency = this.user.currency;
          this.originalUser = { ...this.user };
          if (this.user.vetSkillTypes) {
            this.selectedVetSkills = this.user.vetSkillTypes;
          }
          this.place = {
            address: this.user.address,
            city: this.user.city,
            country: this.user.country,
            googlePlaceId: this.user.googlePlaceId,
            geoPoint: this.user.geoPoint
          } as Place;
          if (this.user.profileImageRef) {
            const ref = storage.ref(this.user.profileImageRef);
            this.profileImageUrl = await ref.getDownloadURL();
          }
          if (this.user.cvFileRef) {
            const ref = storage.ref(this.user.cvFileRef);
            this.cvUrl = await ref.getDownloadURL();
          }
        });
    },
    updateData() {
      this.salarySelected();
      if (this.selectedVetSkills) {
        this.user.vetSkillTypes = this.selectedVetSkills;
      }
      db.collection('users')
        .doc(this.$store.state.currentUser.uid)
        .set(this.user, { merge: true })
        .then(() => {
          this.showSnackbar('User updated.', 'success');
          this.originalUser = { ...this.user };
        })
        .catch((error: Error) => {
          this.showSnackbar(error.message, 'error');
          console.error(error);
        });
    },
    placeSelected(place: Place) {
      this.user.googlePlaceId = place.googlePlaceId;
      this.user.address = place.address;
      this.user.city = place.city;
      this.user.country = place.country;
      this.user.geoPoint = place.geoPoint;
    },
    salarySelected() {
      this.user.currency = this.currency;
    },
    launchImageUpload() {
      (this.$refs.imageUpload as any).$refs.input.click();
    },
    async onProfileImageUpload() {
      this.uploadProgress = 0;
      try {
        const ref = storage.ref(`user/profileImages/${this.user.uid}`);
        const task = ref.put((this.profileImage as unknown) as Blob);
        task.on('state_changed', (snapshot: any) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        });
        await task.then().catch((error: Error) => {
          console.error(error);
        });
        this.user.profileImageRef = `user/profileImages/${this.user.uid}_500x500`;
        this.updateData();
        this.profileImageUrl = await ref?.getDownloadURL();
        this.showSnackbar('Profile Image uploaded.', 'success');
      } catch (error) {
        console.error(error);
      }
    },
    launchCvUpload() {
      (this.$refs.cvFileUpload as any).$refs.input.click();
    },
    async onCvFileUpload() {
      this.uploadProgress = 0;
      try {
        const ref = storage.ref(
          `user/cvs/${this.user.uid}/${((this.cvFile as unknown) as File).name}`
        );
        const task = ref.put((this.cvFile as unknown) as Blob);
        task.on('state_changed', (snapshot: any) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        });
        await task.then().catch((error: Error) => {
          console.error(error);
        });
        this.user.cvFileRef = `user/cvs/${this.user.uid}/${
          ((this.cvFile as unknown) as File).name
        }`;
        this.updateData();
        this.cvUrl = await ref?.getDownloadURL();
        this.showSnackbar('CV uploaded.', 'success');
      } catch (error) {
        console.error(error);
      }
    },
    onMobileNumberInput(formattedNumber, { number }) {
      this.user.mobileNumber = number.international;
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  created() {
    this.getData();
  }
  // beforeRouteLeave(to, from, next) {
  //   if (JSON.stringify(this.originalUser) !== JSON.stringify(this.user)) {
  //     this.updateData();
  //     setTimeout(() => {
  //       next();
  //     }, 1000);
  //   } else {
  //     next();
  //   }
  // }
});
